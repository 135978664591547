let timer: any = null;
import store from '../store/index';
import Bridge from "./bridge";
let OS = store.state.OS;
import LocalStore from 'store'
export function setOS(os: any) {
    OS = os;
}
import axios from "axios"
export function exitModel() {
    try {
        if (OS == "iOS") {
            Bridge.callhandler(
                "tapTeenageModeClose",
                { oprate: "exit" },
                (data) => { }
            );
        }
        if (OS == "Android") {
            // @ts-ignore
            window.Podo.androidClose();
        }
    } catch (error) {
        console.error("==exitModel====", error);
    }
}

export function timeProhibit() {
    try {
        if (OS == "iOS") {
            Bridge.callhandler(
                "teenageModeForBidenPeriod",
                { oprate: "Period" },
                (data) => { }
            );
        }
        if (OS == "Android") {
            // @ts-ignore
            window.Podo.androidProhibit();
        }
    } catch (error) {
        console.error("==timeProhibit====", error);
    }
}

export function timeOut() {
    try {
        if (OS == "iOS") {
            Bridge.callhandler(
                "teenageModeTimeLimitWarning",
                { oprate: "Period" },
                (data) => { }
            );
        }
        if (OS == "Android") {
            // @ts-ignore
            window.Podo.androidTimeout();
        }
    } catch (error) {
        console.error("==timeProhibit====", error);
    }
}

export function timeStart() {
    timer = setInterval(() => {
        judgeNowTime();
        let timeCount = store.state.timeCount;
        if (timeCount > 40 * 60) {
            clearTimer();
            timeOut();
            return;
        }
        let c = timeCount + 1;
        store.commit("UPDATE_TIME_COUNT", c);
        LocalStore.set("timeCount", c);
    }, 1000);
}
export function clearTimer() {
    if (timer) {
        clearInterval(timer);
    }
}

export function iosRestSuccessLock() {
    Bridge.registerhandler("successLock", (data, responseCallback) => {
        successLock()
    });
}
export async function successLock() {
    clearTimer();
    const now = await getNow();
    store.commit("UPDATE_TIME_COUNT", 0);
    LocalStore.set("timeCount", 0);
    LocalStore.set("readTime", now);
    timeStart();
}
let nowTimeInstace = new Date();
export async function getNow() {
    const res = await axios.get("https://share.podoteng.com/getTime");
    nowTimeInstace = res.data?.time ? new Date(res.data.time) : new Date();
    return nowTimeInstace;
}

export async function judgeReadTime() {
    clearTimer();
    //@ts-ignore
    window.successLock = successLock;
    //判斷此時是不是閱讀時間
    const now = await getNow();
    //判断使用时间
    let readTime: Date = LocalStore.get("readTime");
    if (readTime) {
        readTime = new Date(readTime);
        if (now.getTime() - readTime.getTime() > 24 * 60 * 60 * 1000) {
            LocalStore.set("timeCount", 0);
            LocalStore.set("readTime", now);
        } else {
            let num = LocalStore.get("timeCount") ? LocalStore.get("timeCount") : 0;
            store.commit("UPDATE_TIME_COUNT", num);
            if (num > 40 * 60) {
                return;
            }
            timeStart();
        }
    } else {
        readTime = now;
        LocalStore.set("readTime", now);
        timeStart();
        LocalStore.set("timeCount", 0);
    }
    iosRestSuccessLock();
}

export function judgeNowTime() {

    const now = new Date(nowTimeInstace.getTime());
    const hours = now.getHours();
    if (hours < 6) {
        clearTimer()
        timeProhibit();
        return;
    }
    const h22 = new Date(now.getTime()).setHours(22);
    if (now.getTime() >= h22) {
        clearTimer()
        timeProhibit();
        return;
    }
}

export default OS;