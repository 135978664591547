
import Vue from "vue";
import axios from "@/plugins/axios";
import QS from "qs";
import Podo from "@/podo-ts/pd";
export default Vue.extend({
  data(): any {
    return {
      list: [],
      noNotice: false,
      value: 0,
      podo: new Podo(),
      isLight: false,
      dark:true
    };
  },
  computed: {
    noNoticeMT() {
      return (document.body.clientHeight - 40) / 2;
    },
  },
  async mounted() {
    document.title = "PODO";
    let OS = this.$store.state.OS;
    let { type, id, from, focus_id } = QS.parse(location.href.split("?")[1]);
    setTimeout(async (_) => {
      // let inPodo = this.$store.state.inPodo;
      // if (inPodo) {
      //   const info = await this.podo.podoInfo();
      //   this.isLight = info.data.style.theme == "light" ? true : false;
      //   this.$vuetify.theme.dark = !this.isLight;
      // }
      let platform = "";
      let res: any = null;
      if (from == "podo") {
        platform = OS == "iOS" ? "IA" : OS == "Android" ? "AA" : "MW";
      } else {
        platform = OS == "iOS" || OS == "Android" ? "MW" : "PW";
      }
      let url: string = "";
      if (type && type == "content" && id) {
        url = `/notification/v1/content-notice/${id}?podoClientPlatform=${platform}&cursor=`;
      } else {
        url = `/notification/v1/notice?podoClientPlatform=${platform}`;
      }
      res = await axios.get(url, {
        headers: {
          "x-kakao-webtoon-platform": platform,
        },
      });
      this.list = res.data;
      if (focus_id) {
        for (let index = 0; index < res.data.length; index++) {
          if (res.data[index].id == focus_id) {
            this.value = index;
            break;
          } else {
            continue;
          }
        }
      }

      if (this.list.length == 0) {
        this.noNotice = true;
      } else {
        this.noNotice = false;
      }
    }, 500);
  },
});
