import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import VueXss from 'vue-xss'
import sensors from 'sensors'
import Exposure from '@/helper/exposure'
import Click from '@/helper/click'
Vue.use(VueXss)
Vue.config.productionTip = false


Object.defineProperties(Vue.prototype, {
  $sensors: {
    get() {
      return sensors;
    },
  },
});
const exp = new Exposure()
const cli = new Click()
//神策初始化
setTimeout(() => {
  if (sensors) {
    sensors.init({
      server_url: process.env.VUE_APP_SA,
      cross_subdomain: false,
      heatmap: {
        clickmap: 'default',
        scroll_notice_map: 'not_collect'
      },
      show_log: false,
      is_track_single_page: true,
      use_client_time: true,
      send_type: 'beacon'
    });
  }
}, 10)
export const aegis = new window.Aegis({
  id: process.env.NODE_ENV == "production" ? 'DvVQ0cEQPmwprKdx3P' : 'VD3QLtd70WDmxqb45Q', // 上报 id 
  uin: process.env.NODE_ENV == "production" ? 'app-share' : 'app-share-test', // 用户唯一 ID（可选）
  reportApiSpeed: true, // 接口测速
  reportAssetSpeed: true, // 静态资源测速
  spa: true // spa 应用页面跳转的时候开启 pv 计算
});
Vue.prototype.$aegis = aegis;
Vue.config.errorHandler = function (err, vm, info) {
  console.log(`Error: ${err.toString()}\nInfo: ${info}`);
  aegis.error(`Error: ${err.toString()}\nInfo: ${info}`);
};



export default new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
process.env.NODE_ENV == 'development' ? new window.VConsole() : '';
//@ts-ignore
Date.prototype.format = function (fmt) {
  var o = {
    "M+": this.getMonth() + 1, //月份 
    "d+": this.getDate(), //日 
    "h+": this.getHours() % 12 == 0 ? 12 : this.getHours() % 12, //小时 
    "H+": this.getHours(), //小时 
    "m+": this.getMinutes(), //分 
    "s+": this.getSeconds(), //秒 
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
    "S": this.getMilliseconds() //毫秒 
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}