import 'intersection-observer'
import {
    track
} from './sendData'


export default class Exposure {
    cacheDataArr: [];
    maxNum: number;
    params: any;
    _observer: any;
    expression = ""
    constructor(maxNum = 20) {
        this.cacheDataArr = []
        this.maxNum = maxNum
        this._observer = null
        this.init()
    }
    /**
     * 初始化
     */
    init() {
        const self: any = this
        // 实例化监听
        this._observer = new IntersectionObserver(function (entries, observer) {
            entries.forEach((entry: any) => {
                // 出现在视窗中
                if (entry.isIntersecting) {
                    // 获取参数
                    // if (self.params) {
                    //     track(self.params);
                    // }
                    if (typeof (self.expression) == "function") {
                        self.expression();
                    }
                }
            })
        }, {
            root: null,
            rootMargin: '0px',
            threshold: 0.5 // 元素出现面积，0 - 1，这里当元素出现一半以上则进行曝光
        })
    }

    /**
     * 给元素添加监听
     * @param {Element} entry 
     */
    add(entry: any) {
        this._observer && this._observer.observe(entry.el)
    }

    setExpression(expression: any) {
        this.expression = expression;
    }

    setPrams(parms: any) {
        this.params = parms;
    }

    /**
     * 埋点上报
     */


}