
// 此是 原生通信js

import { PodoResponse } from '@/podo-ts/type/podo'
let podotengInit: Podoteng | null = null;
import Podoteng, { getPodotengInstance } from './podoteng';

export function podoResponse<T>(res: PodoResponse<T>) {
    responseFactory(res);
}

//接收app回调函数工厂
export function responseFactory<T>(res: PodoResponse<T>) {
    podotengInit = getPodotengInstance();
    podotengInit.responseFactory(res);
}

//回到webview
export function webviewResume<T>() {
    podotengInit = getPodotengInstance();
    podotengInit.webviewResume();
}
//暂停
export function webviewPause<T>() {
    podotengInit = getPodotengInstance();
    podotengInit?.webviewPause();
}
//全部加载完成
export function webviewLoadFinish<T>(res: PodoResponse<T>) {
    podotengInit = getPodotengInstance();
    podotengInit?.webviewLoadFinish();
}
// 获取分享信息
export function shareGetConfig() {
    podotengInit = getPodotengInstance();
    return podotengInit?.shareGetConfig();

}

