import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    OS: '',
    inPodo: '',
    isMicroMessengerApp: false,
    imageList: new Array(),
    timeCount: 0,
    chapterList: [],
    snackbarList: new Array(),
    clientWidth: 0,
    clientHeight: 0,
    contentIamge: {},
    contentId: 0,
    isACApp: false,
    platformList: [],
    platformValue: "",
    platformCode: "",
    channelId: "",
    vendor: "unknown",
    title: "",
    theme: "",
  },
  mutations: {
    SET_OS(state, os) {
      state.OS = os;
    },
    IS_In_Podo(state, flag) {
      state.inPodo = flag;
    },
    IS_Micro_Messenger_APP(state, flag) {
      state.isMicroMessengerApp = flag;
    },
    UPDTAE_IMAGES_LIST(state, list) {
      state.imageList = list;
    },
    UPDTAE_IMAGES_ITEM(state, item: any) {
      state.imageList.push(item);
    },
    UPDTAE_CONTENT_IAMGE(state: any, obj) {
      const contentId = obj.contentId.toString();
      if (!state.contentIamge.hasOwnProperty(contentId)) {
        state.contentIamge = Object.assign({}, state.contentIamge, {
          [contentId]: []
        })
      }
      let arr = Array.from(state.contentIamge[contentId]);
      arr.push(obj);
      state.contentIamge = Object.assign({}, state.contentIamge, {
        [contentId]: arr
      })
    },
    CLEAR_CONTENT_IAMGE(state: any, contentId) {
      if (state.contentIamge.hasOwnProperty(contentId)) {
        state.contentIamge = Object.assign({}, state.contentIamge, {
          [contentId]: []
        })
      }
    },
    UPDTAE_CONTENT_ID(state, id) {
      state.contentId = id;
    },
    UPDATE_TIME_COUNT(state, num) {
      state.timeCount = num;
    },
    UPDATE_CHAPTER_LIST(state, list) {
      state.chapterList = list;
    },
    IS_AC_APP(state, flag) {
      state.isACApp = flag;
    },
    UPDATE_SNACKBAR_LIST(state, ob) {
      state.snackbarList.push(ob);
      setTimeout(_ => {
        state.snackbarList.shift();
      }, state.snackbarList.length * 3000)
    },
    UPDATE_CLIENT_WIDTH(state, num) {
      state.clientWidth = num;
    },
    UPDATE_CLIENT_HEIGHT(state, num) {
      state.clientHeight = num;
    },
    UPDATA_PLAYFORM_LIST(state, res) {
      state.platformList = res
    },
    UPDATA_PLAYFORM_VALUE(state, res) {
      state.platformValue = res
    },
    UPDATA_PLAYFORM_CODE(state, res) {
      state.platformCode = res
    },
    UPDATA_CHANNELID(state, res) {
      state.channelId = res
    },
    SET_VENDOR(state, vendor) {
      state.vendor = vendor;
    },
    UPDATA_TITLE(state, value) {
      state.title = value
    },
    SET_THEME(state, value) {
      state.theme = value;
    }
  },
  actions: {
  },
  modules: {
  }
})
