//此是 公用js
import Podoteng from "@/podo-ts/podoteng";
interface Params {
    callback?: Function,
}
interface PramsRefer extends Params {
    refer?: string,
    show?: boolean
}
export interface ShareParams extends Params {
    title: string,
    image: string,
    describe: string,
    url?: string,
    contentId?: number | string,
    pageId?: number | string,
    pageName?: number | string
}
interface SubscribeParams extends Params {
    contentId: number | string,
}
interface SubscribeListParams extends Params {
    contentIds: number[] | string[],
}

interface StorageReferParams extends Params {
    key: string,
}
interface StorageReferValueParams extends Params {
    key: string,
    value: string,
}
interface HttpParams extends Params {
    url: string,
    methods: "GET" | "POST" | "PUT" | "DELETE" | "PATCH",
    params?: any,
    header?: any,
}

interface TrackParams extends Params {
    event: string,
    params: any,
}

interface SchemeParams extends Params {
    url: string
}

interface WeChatProgramParams extends Params {
    userName: string;
    path: string;
    miniProgramType: 0 | 1 | 2;
    extMsg?: string;
    extDic?: string;
}

import { getPodotengInstance } from "@/podo-ts/podoteng"
export default class PodoFun {

    podoInfo(params?: Params): Promise<any> {
        return this.getPromise("podoInfo", params);
    }
    //获取权限
    podoPromission(params?: Params): Promise<any> {
        return this.getPromise("podoPromission", params);
    }

    //调取分享
    shareOn(params: ShareParams): Promise<any> {
        return this.getPromise("shareOn", params);
    }
    //获取分享渠道
    shareChannel(params?: ShareParams) {
        return this.getPromise("shareChannel", params);
    }
    //订阅作品
    subscribeOn(params: SubscribeParams) {
        return this.getPromise("subscribeOn", params);
    }

    //取消订阅
    subscribeCancel(params: SubscribeParams) {
        return this.getPromise("subscribeCancel", params);
    }

    //多个订阅
    subscribeOnList(params: SubscribeListParams) {
        return this.getPromise("subscribeOnList", params);
    }

    //获取分区储存所有值
    storageRefer(params?: Params) {
        return this.getPromise("storageRefer", { ...params, refer: this.getRefer() });
    }
    // 获取分区中的某个值
    storageGet(params: StorageReferParams) {
        return this.getPromise("storageGet", { ...params, refer: this.getRefer() });
    }

    // 设置分区中的某个值
    storageSet(params: StorageReferValueParams) {
        return this.getPromise("storageSet", { ...params, refer: this.getRefer() });
    }
    //删除分区健
    storageDel(params: StorageReferParams) {
        return this.getPromise("storageDel", { ...params, refer: this.getRefer() });
    }
    //http
    http(params: HttpParams) {
        return this.getPromise("http", params);
    }
    //返回上一层
    navBack(params?: Params) {
        return this.getPromise("navBack", params);
    }
    //关闭
    navClose(params?: Params) {
        return this.getPromise("navClose", params);
    }
    //重新加载
    navReload(params?: Params) {
        return this.getPromise("navReload", params);
    }

    //调取埋点
    trackOn(params: TrackParams) {
        return this.getPromise("trackOn", params);
    }
    //scheme
    appScheme(params: SchemeParams) {
        return this.getPromise("appScheme", params);
    }
    //主动呼起登录
    login(params?: Params) {
        return this.getPromise("login", params);
    }
    //加载（菊花图）
    uiLoad(params?: Params & { show: boolean }) {
        return this.getPromise("uiLoad", params);
    }

    //Toast
    uiToast(params?: Params & { text: string, time?: number | 3000 }) {
        return this.getPromise("uiToast", { time: 3000, ...params, });
    }

    //设置右上角分享按钮
    uiShareBtn(params: ShareParams & { show: boolean, }) {
        getPodotengInstance().setShareConfig({
            url: params.url,
            title: params.title,
            describe: params.describe,
            image: params.image,
            pageId: params.pageId,
            pageName: params.pageName
        })
        return this.getPromise("uiShareBtn", { show: params.show });
    }
    //打开小程序
    openMicroWeChatProgram(params?: Partial<WeChatProgramParams>) {
        params = Object.assign({
            userName: "gh_4784b57beec8",
            path: "pages/home/home",
            miniProgramType: 0,
            extMsg: '',
            extDic: '', ...params,
        })
        return this.getPromise("openMicroWeChatProgram", params);
    }

    //强制清除缓存
    webviewCacheClear(params?: Params) {
        return this.getPromise("webviewCacheClear", { ...params, refer: this.getDomain() });
    }

    //webviewResumeFactory
    webviewResume(params?: Params) {
        return new Promise((resolve, reject) => {
            getPodotengInstance().webviewResumeFactory("webviewResume", "webviewResume", {
                callback: (res: any) => {
                    resolve(res)
                },
                ...params,
            });
        }
        );
    }
    // webview 暂停
    webviewPause(params?: Params) {
        return new Promise((resolve, reject) => {
            getPodotengInstance().webviewPauseFactory("webviewPause", "webviewPause", {
                callback: (res: any) => {
                    resolve(res)
                },
                ...params,
            });
        }
        );
    }
    // webview 完成
    webviewLoadFinish(params?: Params) {
        return new Promise((resolve, reject) => {
            getPodotengInstance().webviewLoadFinishFactory("webviewLoadFinish", "webviewLoadFinish", {
                callback: (res: any) => {
                    resolve(res)
                },
                ...params,
            });
        }
        );
    }

    webviewShare(params?: Params) {
        return new Promise((resolve, reject) => {
            getPodotengInstance().webviewShareFactory("webviewShare", "webviwebviewShareewLoadFinish", {
                callback: (res: any) => {
                    resolve(res)
                },
                ...params,
            });
        }
        );
    }
    //是否认证
    async isAuth() {
        const res = await this.podoInfo();
        if (res.data?.auth?.userid && res.data?.auth?.userid.length > 6) {
            return true;
        } else {
            return false;
        }
        // return getPodotengInstance().isPodoAuth;
    }

    // 是否为PODO APP
    isApp() {
        return navigator.userAgent.toLowerCase().includes("podo_mobile")
    }

    getPromise(type: string, params?: PramsRefer): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!this.isApp()) {
                reject(new Error('不是PODO APP'))
                return;
            }
            getPodotengInstance().requestFactory(type, type, {
                callback: (res: any) => {
                    resolve(res)
                },
                ...params,
            });
        }
        );
    }
    getRefer() {
        return window.location.hostname.split(".").join("");
    }
    getDomain() {
        return window.location.hostname;
    }
}

