function setupWKWebViewJavascriptBridge(callback) {
    try {
        if (window.WKWebViewJavascriptBridge) {
            return callback(WKWebViewJavascriptBridge);
        }
        if (window.WKWVJBCallbacks) {
            return window.WKWVJBCallbacks.push(callback);
        }
        window.WKWVJBCallbacks = [callback];
        window.webkit.messageHandlers.iOS_Native_InjectJavascript.postMessage(null)
    } catch (error) {
        // console.error("==error==",error);
    }

}

export default {
    callhandler(name, data, callback) {
        setupWKWebViewJavascriptBridge(function (bridge) {
            bridge.callHandler(name, data, callback)
        })
    },
    registerhandler(name, callback) {
        setupWKWebViewJavascriptBridge(function (bridge) {
            bridge.registerHandler(name, function (data, responseCallback) {
                callback(data, responseCallback)
            })
        })
    }
}