import sensors from 'sensors'
export interface SensorsParams {
    page_id: string,
    page_name: string,
    item_type?: "comics" | "activity",
    item_id?: string | number,
    item_name?: string,
    mod_id?: string,
    mod_name?: string,
    button_id?: string,
    button_name?: string,
    $app_id: "h5",
    context_id?: string | number,
    context_name?: string,
    context_type?: "comics" | "activity",
    pop_type?: "click" | "auto",
    platform?: string,
    channel_id?: string

}
export function sensorsTrack(event: string, params: SensorsParams) {
    sensors.track(event, params)
}