import Vue from 'vue';
import Vuetify from 'vuetify'
Vue.use(Vuetify);
const defaultColors = {
    light: {
        primary: "#1976D2",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
        form: '#ECEFF1',
        dialog: '#FAFAFA'
    },
    dark: {
        primary: "#2196F3",
        secondary: "#424242",
        accent: "#FF4081",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
        form: '#263238',
        dialog: '#424242'
    },
};

export default new Vuetify({
    icons: {
        iconfont: "mdi",
        values: {},
    },
    theme: {
        dark: true,
        themes: {
            light: {
                ...defaultColors.light,
                create: defaultColors.light.success,
                read: defaultColors.light.info,
                update: defaultColors.light.success,
                delete: defaultColors.light.error,
                focus: defaultColors.light.primary,
            },
            dark: {
                ...defaultColors.dark,
                create: defaultColors.dark.success,
                read: defaultColors.dark.info,
                update: defaultColors.dark.success,
                delete: defaultColors.dark.error,
                focus: "#ffffff",
            },
        },
        options: {
            customProperties: true,
        },
    },
});
