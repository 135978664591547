import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Notice from '../views/Notice/notice.vue'
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/about',
    name: 'About',

    component: () => import('../views/About.vue')
  },
  {

    path: '/PrivacyAgreement',
    name: 'PrivacyAgreement',

    component: () => import('../views/PrivacyAgreement/privacy.vue')
  },
  {

    path: '/CancellationAgreement',
    name: 'CancellationAgreement',
    component: () => import('../views/CancellationAgreement/cancellation.vue')
  },
  {
    path: '/ServiceAgreement',
    name: 'ServiceAgreement',

    component: () => import('../views/ServiceAgreement/service.vue')
  },
  {
    path: '/RechargeProtocol',
    name: 'RechargeProtocol',

    component: () => import('../views/RechargeProtocol/recharge.vue')
  },

  {
    path: '/notice',
    name: 'notice',
    component: Notice,
  },
  {
    path: '/webview/notice',
    name: 'notice',
    component: Notice,
  },

  {
    path: '/TeenagersHome',
    name: 'TeenagersHome',
    component: () => import('../views/Teenagers/home.vue'),
  },
  { 
    path: '/TeenagersInfo',
    name: 'TeenagersInfo',
    component: () => import('../views/Teenagers/info.vue'),
  },
  {
    path: '/TeenagersRead',
    name: 'TeenagersRead',
    component: () => import('../views/Teenagers/read.vue'),
  },
  {
    path: '/iOSProtocol',
    name: 'iOSProtocol',
    component: () => import('../views/OpenSourceProtocol/iOSProtocol.vue'),
  }, {
    path: '/AndroidProtocol',
    name: 'AndroidProtocol',
    component: () => import('../views/OpenSourceProtocol/AndroidProtocol.vue'),
  },
  {
    path: '/apph5',
    name: 'apph5',
    component: () => import('../views/APPH5/Apph5.vue'),
  },
  {
    path: '/downloadApp',
    name: 'downPage',
    component: () => import('../views/downPage/downPage.vue'),
  },
  {
    path: '/AdProtocol',
    name: 'AdProtocol',
    component: () => import('../views/AdProtocol/page.vue'),
  },
  {
    path: '/PersonalizedRecommendations',
    name: 'PersonalizedRecommendations',
    component: () => import('../views/PersonalizedRecommendations/page.vue'),
  },
  
  {
    path: '*',
    name: 'apph5',
    component: () => import('../views/ErrorPage/404.vue'),
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  mode: 'history',
})
export default router
