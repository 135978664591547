import { PodoResponse, PodoRequest } from '@/podo-ts/type/podo'
import Bridge from "@/podo-ts/bridge";
import { ShareParams } from "@/podo-ts/podo-fun";
export default class Podoteng {
    threadList: any = {};
    webviewResumeFun: Function = () => { };
    webviewPauseFun: Function = () => { };
    webviewLoadFinishFun: Function = () => { };
    webviewShareFun: Function = () => { };
    shareGetConfigFun: Function = () => { };
    shareConfig: ShareParams | null = null;
    os: string | null = null;
    constructor(os?: string) {
        if (!os) {
            this.os = this.getExplore();
        } else {
            this.os = os;
        }
    }

    isPodoAuth = false;

    setIsPodoAuth(flag: boolean) {
        this.isPodoAuth = flag
    }

    setShareConfig(config: ShareParams) {
        this.shareConfig = config;
    }
    //js to app 1
    podoRequest<T>(req: PodoRequest<T>): void {
        if (this.os == "Android" && window.Podo) {
            return window.Podo.podoRequest(JSON.stringify(req))
        }
        if (this.os == "iOS") {
            return Bridge.callhandler(
                "podoRequest",
                req,
                (data: any) => { }
            );
        }
        //new App().podoRequest(req);
    }


    //app to js
    podoResponse<T>(res: PodoResponse<T>) {
        this.responseFactory(res);
    }
    //接收app回调函数工厂
    responseFactory<T>(res: any) {
        let res2: any = null;
        try {
            res2 = typeof (res) == "object" ? res : JSON.parse(res.replace(/[\r]/g, "\\r").replace(/[\n]/g, "\\n"));
            const data = res2.data;
            if (typeof (data) == "string" && data.length > 0) {
                try {
                    res2.data = JSON.parse(data);
                } catch (error) {
                    res2.data = data;
                }
            }
            let fun = this.threadList[res2.tag];
            if (fun) {
                fun(res2);
                setTimeout(() => {
                    delete this.threadList[res2.tag];
                }, 0)

            }
        } catch (error) {
            console.error("==responseFactory==9999==", error);
        }

    }

    //webviewResume
    webviewResume<T>() {
        this.webviewResumeFun();
    }

    //webviewPause
    webviewPause<T>() {
        this.webviewPauseFun();
    }

    //webviewPause
    webviewLoadFinish<T>() {
        this.webviewLoadFinishFun();
    }

    //shareGetConfig
    shareGetConfig<T>() {
        this.webviewShareFun();
        return this.shareConfig;
    }

    // js to app factory  request
    requestFactory<T>(callback: string, type: string, params?: any,) {
        const tag = this.tagFactory();
        if (params && params.callback) {
            this.threadList[tag.toString()] = params.callback;
            const timer = setTimeout(() => {
                if (this.threadList[tag.toString()]) {
                    this.threadList[tag.toString()]({
                        status: 404,
                        msg: "超时",
                        callback: callback,
                        tag: tag.toString(),
                        data: null,
                    })
                    delete this.threadList[tag.toString()];
                    clearTimeout(timer);
                }
            }, 60 * 1000)
        }
        this.podoRequest({
            callback: callback,
            type: type,
            tag: tag,
            params: params,
        });
    }

    // js to app factory webviewResume
    webviewResumeFactory<T>(callback: string, type: string, params?: any,) {
        this.webviewResumeFun = params.callback;
    }

    // js to app factory webviewPause
    webviewPauseFactory<T>(callback: string, type: string, params?: any,) {
        this.webviewPauseFun = params.callback;

    }

    // js to app factory webviewLoadFinish
    webviewLoadFinishFactory<T>(callback: string, type: string, params?: any,) {
        this.webviewLoadFinishFun = params.callback;
    }

    // js to app factory webviewShareFactory
    webviewShareFactory<T>(callback: string, type: string, params?: any,) {
        this.webviewShareFun = params.callback;
    }
    //tag 随机工厂
    tagFactory(): number {
        return new Date().getTime() + Math.floor(Math.random() * 1000);
    }

    getExplore() {
        let ua = navigator.userAgent.toLowerCase();
        if (ua.includes("iphone")) return "iOS";
        if (ua.includes("android")) return "Android";
        if (ua.includes("macintosh")) return "macintosh";
        if (ua.includes("windows")) return "windows";
        return "unknown";
    }
}
let podoteng: null | Podoteng = null;
export function getPodotengInstance(): Podoteng {
    if (podoteng != null) {
        return podoteng;
    } else {
        podoteng = new Podoteng();
        if (podoteng.os == "iOS") {
            Bridge.registerhandler("podoResponse", (data: any, responseCallback: any) => {
                podoteng?.podoResponse(data);
            });
            Bridge.registerhandler("webviewResume", () => {
                podoteng?.webviewResume();
            });
            Bridge.registerhandler("webviewPause", () => {
                podoteng?.webviewPause();
            });
            Bridge.registerhandler("webviewLoadFinish", () => {
                podoteng?.webviewLoadFinish();
            });
            Bridge.registerhandler("shareGetConfig", (data: any, responseCallback: any) => {
                responseCallback(podoteng?.shareGetConfig());
            });
        }
        return podoteng
    }
}
