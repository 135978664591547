
declare global {
    interface Window {
        WKWebViewJavascriptBridge: any,
        WVJBCallbacks: any,
        WKWVJBCallbacks: any,
        webkit: any
    }
}


function setupWKWebViewJavascriptBridge(callback: any) {
    try {
        if (window.WKWebViewJavascriptBridge) {
            return callback(window.WKWebViewJavascriptBridge);
        }
        if (window.WKWVJBCallbacks) {
            return window.WKWVJBCallbacks.push(callback);
        }
        window.WKWVJBCallbacks = [callback];
        window.webkit.messageHandlers.iOS_Native_InjectJavascript.postMessage(null)
    } catch (error) {
        console.error("==setupWKWebViewJavascriptBridge==", error);
    }

}

export default {
    callhandler(name: string, data: any, callback: any) {
        setupWKWebViewJavascriptBridge(function (bridge: any) {
            bridge.callHandler(name, data, callback)
        })
    },
    registerhandler(name: string, callback: any) {
        setupWKWebViewJavascriptBridge(function (bridge: any) {
            bridge.registerHandler(name, function (data: any, responseCallback: any) {
                callback(data, responseCallback)
            })
        })
    }
}