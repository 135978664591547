import PodoFun from "@/podo-ts/podo-fun"
import { getPodotengInstance } from "@/podo-ts/podoteng"
import { podoResponse, webviewResume, webviewPause, webviewLoadFinish, shareGetConfig } from "@/podo-ts/base";
declare global {
    interface Window {
        podoResponse: any,
        webviewResume: any,
        webviewPause: any,
        webviewLoadFinish: any,
        shareGetConfig: any,
        Podo: any
    }
}
export default class Podo extends PodoFun {
    constructor() {
        if (isApp()) {
            getPodotengInstance();
            window.podoResponse = podoResponse;
            window.webviewResume = webviewResume;
            window.webviewPause = webviewPause;
            window.shareGetConfig = shareGetConfig;
            window.webviewLoadFinish = webviewLoadFinish;
        } else {
            console.error("===不是 PODO APP 初始化失败===");
            new Error("未初始化PodotengJs")
        }
        super();
    }
}

export function isApp() {
    return navigator.userAgent.toLowerCase().includes("podo_mobile") || navigator.userAgent.toLowerCase().includes("webtoon")
}