
import Vue from "vue";
import { setOS } from "./helper/os";
import "@/assets/font/iconfont.css";
import Podo from "@/podo-ts/pd";
export default Vue.extend({
  name: "App",
  data: () => ({
    //
  }),

  created() {
    this.$store.commit("SET_OS", getExplore(this));
    setOS(getExplore(this));
    this.$store.commit("UPDATE_CLIENT_WIDTH", document.body.clientWidth);
    this.$store.commit("UPDATE_CLIENT_HEIGHT", document.body.clientHeight);
    window.onresize = () => {
      return (() => {
        this.$store.commit("UPDATE_CLIENT_WIDTH", document.body.clientWidth);
        this.$store.commit("UPDATE_CLIENT_HEIGHT", document.body.clientHeight);
      })();
    };
    this.$router.onReady(() => {
      const platformValue = this.$route.query.platform
        ? this.$route.query.platform
        : "";
      const channel_id = this.$route.query.channel_id
        ? this.$route.query.channel_id
        : "";
      this.$store.commit(
        "UPDATA_PLAYFORM_VALUE",
        platformValue ? platformValue : ""
      );
      this.$store.commit("UPDATA_CHANNELID", channel_id ? channel_id : "");
    });
    // getPlatForm().then((res) => {
    //   if (res.status == 200) {
    //     this.$store.commit("UPDATA_PLAYFORM_LIST", res.data.data);
    //   }

    //   this.$store.commit("UPDATA_PLAYFORM_CODE", platform());
    // });
  },
  async mounted() {
    // this.$vuetify.theme.dark = false
    const podo = new Podo()
    if (podo.isApp()) {
      const info = (await podo.podoInfo()).data;
      this.$vuetify.theme.dark = info.style.theme == 'dark';
    }
  },
  computed: {
    snackbar() {
      return this.$store.state.snackbarList.length > 0;
    },
    text() {
      return this.$store.state.snackbarList[0]
        ? this.$store.state.snackbarList[0].text
        : "";
    },
  },
});
function getExplore(_this) {
  let ua = navigator.userAgent.toLowerCase();
  //podo浏览器
  if (ua.includes("podo")) {
    _this.$store.commit("IS_In_Podo", true);
  }
  //微信浏览器
  if (ua.includes("micromessenger")) {
    _this.$store.commit("IS_Micro_Messenger_APP", true);
  }
  //腾漫浏览器
  if (ua.includes("qqac_client")) {
    _this.$store.commit("IS_AC_APP", true);
  }
  if (ua.includes("iphone")) return "iOS";
  if (ua.includes("android")) return "Android";
  if (ua.includes("macintosh")) return "macintosh";
  if (ua.includes("windows")) return "windows";
  return "unknown";
}
